class Slideshow {

    constructor (container) {
        this.container     = container,
        this.slides        = container.querySelectorAll('.m__slider__item'),
        this.bullets       = container.querySelectorAll('.m__slider__bullet__item')
        this.current       = container.querySelector('.m__slider__item.trigger'),
        this.bulletCurrent = container.querySelector('.m__slider__bullet__item.trigger')
        this.counter       = 0
        this.amount        = this.slides.length
        this.timing        = container.getAttribute('data-timing')
        this.interval      = undefined
    }

    init() {
        this.timeout()
        this.registerEvents()
    }

    registerEvents() {
        this.container.addEventListener('click', this.handleClick.bind(this))
    }

    handleClick(e) {
        let el = e.target

        if (el.classList.contains('previous'))
            this.reloader(-1)

        if (el.classList.contains('next'))
            this.reloader(1)

        if (el.classList.contains('m__slider__bullet__item'))
            this.bulletSwitch(el)
    }

    switch (direction=1) {

        this.current.classList.remove('trigger')

        if (this.bulletCurrent)
            this.bulletCurrent.classList.remove('trigger')
            
        this.counter = this.counter + direction

        if (direction === -1 && this.counter < 0)
            this.counter = +this.amount - 1
        if (direction === 1 && !this.slides[this.counter]) {
            this.counter = 0
        }

        this.current = this.slides[this.counter]
        this.current.classList.add('trigger')
        
        if (this.bulletCurrent) {
            this.bulletCurrent = this.bullets[this.counter]
            this.bulletCurrent.classList.add('trigger')
        }
    }

    timeout() {

        if (!this.timing)
            return 
        
        this.interval = setInterval(this.switch.bind(this), this.timing * 1000)
    }

    reloader (direction) {
        clearInterval(this.interval)
        this.switch(direction)
        this.timeout()
    }

    bulletSwitch(el) {

        if (!el.hasAttribute('data-index') || el === this.bulletCurrent)
            return

        this.bulletCurrent.classList.remove('trigger')
        this.current.classList.remove('trigger')

        this.counter = +el.getAttribute('data-index')

        this.current = this.slides[this.counter]
        this.bulletCurrent = this.bullets[this.counter]
        this.current.classList.add('trigger')
        this.bulletCurrent.classList.add('trigger')

        if (this.timing) {
            clearInterval(this.interval)
            this.timeout()
        }
    }
}

export {
    Slideshow
}