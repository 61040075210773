import { Slideshow } from './module/slideshow'
import { ECHILD } from 'constants';

(function () {
    'use strict'

    const body = document.body

    const sliders = document.querySelectorAll('.m__slider')

    for (let i=0, lng=sliders.length; i<lng; i++) {

        let slider = new Slideshow(sliders[i])
        slider.init()
    }

    const displayForm = el => {

        if (document.querySelector('.m__fixedForm__trigger').classList.contains('trigger'))
            return 
        
        document.querySelector('.m__fixedForm__trigger').classList.add('trigger')
        document.querySelector('.m__fixedForm').classList.add('trigger')
        body.classList.add('overflow')
    }

    const closeForm = el => {
        document.querySelector('.m__fixedForm__trigger').classList.remove('trigger')
        document.querySelector('.m__fixedForm').classList.remove('trigger')
        body.classList.toggle('overflow')
    }

    body.addEventListener('click', e => {

        let el = e.target

        if (el.classList.contains('m__fixedForm__trigger') || (el.classList.contains('m__cta') && el.type !== 'submit'))
            return displayForm(el)

        if (el.classList.contains('m__fixedForm__closer'))
            return closeForm()
    })

})()